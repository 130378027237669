var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "modules.create",
              expression: "'modules.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Grid", {
        attrs: { service: _vm.service, route_name: "modules" },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("module.name"))),
                ]),
                _c(
                  "vs-th",
                  { key: "description", attrs: { "sort-key": "description" } },
                  [_vm._v(_vm._s(_vm.$t("module.description")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "30%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.name))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "description", attrs: { width: "40%" } },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: tr.description,
                              expression: "tr.description",
                            },
                          ],
                        }),
                      ]
                    ),
                    _c(
                      "vs-td",
                      { attrs: { width: "10%" } },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "modules.edit",
                              expression: "'modules.edit'",
                            },
                          ],
                          attrs: {
                            icon: "EditIcon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                          },
                          on: {
                            click: function ($event) {
                              return props.edit(props.data[indextr].id)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "modules.delete",
                              expression: "'modules.delete'",
                            },
                          ],
                          attrs: {
                            icon: "Trash2Icon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                          },
                          on: {
                            click: function ($event) {
                              return props.destroy(props.data[indextr])
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }